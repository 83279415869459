ul.breadcrumb {
    list-style: none;
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $usgbc-primary-1;
}

ul.breadcrumb li {
    display: inline;
}

ul.breadcrumb li + li::before {
    padding: 8px;
    content: "/\00a0";
}

ul.breadcrumb li a {
    text-decoration: none;
    color: #353535;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

ul.breadcrumb li a:hover {
    text-decoration: underline;
}
