/* stylelint-disable selector-class-pattern */

mat-dialog-container.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        border-radius: 16px;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: none;
    }
}

.right-side-drawer mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cdk-overlay-pane {
    overflow: auto;
}