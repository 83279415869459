// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Plus imports for other components in your app.
// You can add global styles to this file, and also import other style files
@import "./variables";
@import "./theme";
@import "./layout/material-toolbar";
@import "./layout/breadcrumb";
@import "./layout/message-calculator-button";
@import "./stepper";
@import "./input";
@import "./text";
@import "./logo";
@import "./paginator";
@import "./cards";
@import "./button";
@import "./tabs";
@import "./dialog";
@import "./tables";
@import "./custom-card";
@import "./custom-menu";
@import "./property-tools-menu";
@import "./sidebar";
@import "./custom-snackbar";
@import "./custom-button-toggle-group";

// TODO: remove this and register icons
@import "./icons";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@media screen and (width > 1440px) {
    body {
        margin: 0px;
    }

    html {
        overflow-y: hidden;
    }
}