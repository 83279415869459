.usgbc-snack-bar {
    &.success {
        --mdc-snackbar-container-color: #EBFAEF;
        --mdc-snackbar-supporting-text-color: #2D9F75;
    }

    &.alert, &.error {
        --mdc-snackbar-container-color: #FFE7E7;
        --mdc-snackbar-supporting-text-color: #FF3838;
    }

    &.warning {
        --mdc-snackbar-container-color: #FFF8DE;
        --mdc-snackbar-supporting-text-color: #AF881D;
    }

    &.info {
        --mdc-snackbar-container-color: #E2EAFB;
        --mdc-snackbar-supporting-text-color: #3F76E4;
    }
}