.mat-mdc-menu-panel.mat-mdc-menu-panel.property-tools-menu {
    border-radius: 16px;
    min-width: 112px;
    max-width: 304px;
    overflow: auto;
    position: relative;
    top: 8px;

    .mat-mdc-menu-content {
        margin: 0;
        padding: 0;

        .mat-mdc-menu-item {
            padding: 12px 0;
        }

        .tools-menu-loading {
            width: 160px;
            margin: 12px 24px;
        }

        .tools-menu-grid {
            margin: 12px 24px;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 80px));
            grid-auto-rows: 108px;
            gap: 8px 8px;

            .tools-menu-item {
                border-radius: 16px;

                /* stylelint-disable selector-class-pattern */
                .mdc-list-item__primary-text {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;
                    align-items: center;

                    .menu-item-icon {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        justify-content: center;

                        .wrap {
                            width: 40px;
                            height: 40px;
                            background: #D3E73666;
                            border-radius: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .img-icon {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    .menu-item-title {
                        width: 100%;
                        height: 32px;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}