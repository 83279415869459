.mat-mdc-menu-panel.mat-mdc-menu-panel.custom-menu {
    border-radius: 16px;
    min-width: 112px;
    max-width: 328px;
    overflow: auto;
    position: relative;
    top: 22px;
    left: 20px;

    &.import-data {
        top: 8px;
        left: 0;
        background-color: var(--usgbc-gray-1, #FAF9F9);
        box-shadow: 0 1px 8px 0 rgb(175 175 175 / 25%);
        border: 1px solid var(--usgbc-gray-2, #F0F0F0);

        /* stylelint-disable selector-class-pattern */
        .mat-mdc-menu-item.mdc-list-item {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            text-decoration-line: underline;
            color: var(--usgbc-primary-1, #353535);
        }
    }

    .mat-mdc-menu-content {
        margin: 0;
        padding: 0;

        .menu-title {
            padding: 13px 16px;
            font-size: 16px;
            height: 48px;
            box-sizing: border-box;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-bottom: 1px solid $usgbc-gray-3;
        }

        hr.separator {
            margin: 0 16px;
            height: 0;
            border: 0;
            border-top: 1px solid var(--usgbc-gray-3, #E7E7E7);
        }

        .project-menu-item .mdc-list-item__primary-text {
            width: 328px;
            height: 56px;
            display: flex;
            flex-flow: row wrap;
            place-content: center space-between;

            .menu-item-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .main-text {
                    font-size: 18px;
                    font-style: normal;
                    line-height: 22px;
                    margin-bottom: 2px;
                }

                .alt-text {
                    color: var(--usgbc-gray-5, #949494);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 16px;
                }
            }

            .menu-item-icon-container {
                display: flex;
                align-items: center;
                gap: 8px;

                .text-link {
                    text-decoration: underline;
                    font-size: 14px;
                }

                .mat-icon {
                    margin: 0;

                    &.small {
                        width: 16px;
                        font-size: 16px;
                        line-height: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}