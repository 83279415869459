.calculator-disclaimer
{
    display: flex;
    width: 377px;
    height: 40px;
    border-radius: 12px;
    background: #E2EAFB;
    margin-bottom: 24px; 

    .info-icon
    {
        padding: 2px 7px;
        color: #3F76E4;
        width: 16px;
        height: 16px;
        font-size: 20px;
        margin-top: auto;
        margin-bottom: 13px;

    }

    .selected-message {
        background-color: var(--usgbc-information-2, #e2eafb);
        height: 20;
        color: var(--usgbc-information-1, #3f76e4);
        border-radius: 4px;
        margin-top: auto;
        margin-bottom: 12px;
    }
}
