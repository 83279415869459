/* stylelint-disable selector-class-pattern */

.mat-mdc-tab .mdc-tab__text-label {
    color: $usgbc-gray-5;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: var(--mdc-tab-indicator-active-indicator-color, $usgbc-primary-3);
    border-radius: 2px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    font-weight: bold;
    color: $usgbc-primary-1;
}

.mat-mdc-tab-list .mat-mdc-tab-labels {
    max-width: 90px;
}

.score-modeling .mat-mdc-tab-list .mat-mdc-tab-labels {
    max-width: 100%;
}

.mat-mdc-tab-body-content{
    margin-top: 25px;
}

.mat-mdc-tab-body-wrapper {
    display: block !important;
}

.mat-mdc-tab-list {
    border-bottom: 1px solid #e7e7e7;
}