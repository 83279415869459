/* MAT-STEPPER */
$mat-step-icon-size: 5px;
$usgbc-hub-primary-1: #353535;
$usgbc-hub-primary-4: #00C08B;
$usgbc-hub-gray: #ECECEC;
$usgbc-hub-step-0: #7BDE92;
$usgbc-hub-step-1: #86DFB0;
$usgbc-hub-step-2: #91DFCE;
$usgbc-hub-step-3: #9CDDEB;
$items: 5;
$colors: (
    0: $usgbc-hub-step-0,
    1: $usgbc-hub-step-1,
    2: $usgbc-hub-step-2,
    3: $usgbc-hub-step-3,
);

@mixin style-edited-step-line($index, $start-color, $end-color) {
    .mat-stepper-horizontal-line:nth-child(-n + #{$index}) {
        background: linear-gradient(to right, $start-color, $end-color) !important;
    }
}

@mixin style-edited-step-icon($index) {
    .mat-horizontal-stepper-header:nth-child(-n + #{$index}) {
        .mat-step-icon {
            background-color: $usgbc-hub-primary-4;
        }
    }
}

@mixin style-unedited-step-icon($index) {
    .mat-horizontal-stepper-header:nth-child(#{$index}) {
        .mat-step-icon:not(.mat-step-icon-selected) {
            background-color: #e8e8e8;
        }
    }
}

mat-stepper.mat-stepper-horizontal {
    @for $i from 0 through $items {
        &.current-step-#{$i} {
            @include style-edited-step-line($i * 2, map-get($colors, $i - 1), map-get($colors, $i));
            @include style-edited-step-icon($i + 1)
        }
    }

    .mat-horizontal-content-container {
        padding: 0 16% 24px;
        overflow: visible;
    }

    .mat-horizontal-stepper-header-container {
        align-items: flex-end;
        margin-bottom: 35px;
    }

    .mat-stepper-horizontal-line {
        border: 0 solid transparent;
        background: $usgbc-hub-gray;
        height: 2px;
        margin: 0 -17% 2px;
        transition: color 1s ease;
    }

    .mat-step-header {
        flex-direction: column-reverse;
        flex: 1;
        padding: 0;
        height: 43px;

        .mat-step-label {
            color: $usgbc-hub-primary-1;
            transition: all 500ms ease;
            display: flex;
            position: relative;
            justify-content: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            margin-bottom: 11px;

            &.mat-step-label-active {
                font-weight: bold;
            }
        }

        .mat-step-icon {
            height: $mat-step-icon-size;
            width: $mat-step-icon-size;
            background-color: $usgbc-hub-gray;
            margin: 0;

            .mat-step-icon-content {
                display: none;
            }
        }

        &:last-of-type {
            .mat-step-icon {
                margin-right: 0;
            }
        }
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: $usgbc-hub-primary-4;
    }

    .mat-step-header .mat-step-icon-state-edit {
        &+.mat-step-label.mat-step-label-active {
            color: $usgbc-hub-primary-1;
            font-weight: 400;
        }

        &~.mat-stepper-horizontal-line {
            border-top-color: $usgbc-hub-primary-4;
        }
    }
}