mat-toolbar.main-toolbar {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 24px;
    background: $mat-toolbar-background-color;
    color: $mat-toolbar-text-color;
    border-bottom: 1px solid $usgbc-gray-3;

    .toolbar-item-spacer {
        flex: 1 1 auto;
    }

    .account {
        border-radius: 50%;
        background-color: var(--usgbc-accent, #D3E736);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        width: 40px;
        height: 40px;
    }
}

.main-toolbar.mat-toolbar-row,
.main-toolbar.mat-toolbar-single-row {
    padding: 0 32px;
    height: $mat-toolbar-height;
}