$card-margin-default: 16px;
$card-background-color: #fff;
$card-background-disabled: var(--usgbc-gray-1, #faf9f9);
$card-border-color: #e7e7e7;
$card-text-default-size: 16px;
$card-text-default-height: 16px;
$card-text-total-height: 24px;

.custom-card-disabled {
    width: 100%;
    height: 100%;
    background-color: $card-background-disabled;
    border-radius: 20px;
    border: 1px solid $card-border-color;
    box-sizing: border-box;
}

.custom-card-default {
    // width: 100%;
    // height: 100%;
    padding: 20px 24px;
    background-color: $card-background-color;
    border-radius: 20px;
    border: 1px solid $card-border-color;
    box-sizing: border-box;

    .container {
        // margin: $card-margin-default;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        .title {
            height: $card-text-default-height;
            margin: 0 0 $card-margin-default 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            text-align: left;
            line-height: $card-text-default-height;
            font-style: normal;
            font-weight: 600;
            font-size: $card-text-default-size;

            .icon-text {
                display: flex;
                align-items: center;

                .info-icon {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    color: var(--usgbc-primary-1, #353535);
                    margin: 0 6px;
                }
            }

            .date {
                color: #949494;
                font-size: 11px;
                font-weight: 400;
                line-height: $card-text-default-height;
            }

            &.column {
                height: auto;
                margin: 0;
                flex-flow: column nowrap;

                .icon-text {
                    margin: 0 0 $card-margin-default 0;
                }

                .date {
                    margin: 0 0 $card-margin-default 0;
                }
            }
        }

        .content {
            margin: 0;
            padding: 0;
            flex: 1 1 auto;
            display: flex;

            &.row {
                flex-flow: row nowrap;
            }

            &.column {
                flex-flow: column nowrap;
            }

            .chart-info {
                margin: 0;
                padding: 0;

                .text-total {
                    height: $card-text-total-height;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $card-text-total-height;
                    line-height: $card-text-total-height;
                }

                .text-total-alt {
                    height: 16px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: $card-text-total-height;
                }

                .text-total-sub {
                    height: 18px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: $card-text-total-height;
                    color: #949494;
                }
            }

            .chart-info-barchart {
                margin-bottom: 16px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;

                .text-total {
                    height: $card-text-total-height;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $card-text-total-height;
                    line-height: $card-text-total-height;
                }

                .text-total-alt {
                    height: 16px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: $card-text-total-height;
                }

                .text-total-sub {
                    height: 18px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: $card-text-total-height;
                    color: #949494;
                }
            }

            .chart-info-cumulative {
                margin-bottom: 16px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;

                .text-total {
                    height: $card-text-total-height;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $card-text-total-height;
                    line-height: $card-text-total-height;
                }

                .text-total-alt {
                    height: 16px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: $card-text-total-height;
                }

                .text-total-sub {
                    height: 18px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: $card-text-total-height;
                    color: #949494;
                }
            }

            .chart-info-breakdown {
                margin-bottom: 16px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;

                .text-total {
                    height: $card-text-total-height;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $card-text-total-height;
                    line-height: $card-text-total-height;
                }

                .text-total-alt {
                    height: 16px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: $card-text-total-height;
                }

                .text-total-sub {
                    height: 18px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: $card-text-total-height;
                    color: #949494;
                }
            }

            .chart-content {
                margin: 0;
                padding: 0;
                flex: 1 1 auto;

                .chart-box {
                    position: relative;
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.w80 {
    width: 80px;
}

.flex-centered {
    place-content: center center;
    align-items: center;
}

.row-centered {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    place-content: center center;
    align-items: center;
}

.custom-toggle-breakdown {
    font-size: 13px;
    font-weight: 400;
    color: #949494;
    background: #f0f0f0;
    padding: 0 6px;
    border-radius: 100px;
    height: 24px;
    display: flex;
    flex-direction: row;
    place-content: center center;
    align-items: center;

    ul.tab {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ul.tab li {
        float: left;
        padding: 0;
    }

    ul.tab li label {
        background: transparent;
        padding: 0 16px;
        display: inline-block;
        border-radius: 100px;
        line-height: 18px;
        vertical-align: middle;
        cursor: pointer;
    }

    ul.tab li input[type='radio'] {
        opacity: 0;
        width: 1px;
        height: 1px;
        margin: 0;
    }

    ul.tab li input[type='radio']:checked~label {
        background: #fff;
        color: #353535;
        font-weight: 600;
        border-radius: 100px;
    }
}
