.usgbc-hub-icon {
    background-position: cover;
    background-repeat: no-repeat;
    height: 48px;
    width: 48px;
}

.cloud-upload {
    background-image: url('../assets/icons/png/cloud-upload.png');

    &.blue {
        background-image: url('../assets/icons/png/cloud-upload-blue.png');
    }
}

.document-download {
    background-image: url('../assets/icons/png/document-download.png');
}

.document-report {
    background-image: url('../assets/icons/png/document-report.png');
}

.trash {
    background-image: url('../assets/icons/png/trash.png');
}

.category-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 8px;
    left: 10px;
}