mat-button-toggle-group.custom-button-toggle-group {
    border-radius: 100px;
    height: 24px;
    background-color: #F0F0F0;
    border: none;

    .mat-button-toggle {
        border: none;
        background: transparent;
        border-radius: 100px;
        margin: 2px 8px;

        .mat-button-toggle-label-content {
            color: var(--usgbc-gray-5, #949494);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        &.mat-button-toggle-checked {
            background: var(--usgbc-pure-white, #FFF);
            box-shadow: 0 0 5px 0 rgb(209 209 209 / 25%);

            .mat-button-toggle-label-content {
                color: var(--usgbc-primary-1, #353535);
            }
        }
    }

    .mat-button-toggle-focus-overlay,
    .mat-button-toggle-ripple {
        border-radius: 100px;
    }
}