// TODO: this will be removed soon
.leed-logo,
.leed-certified-logo,
.leed-silver-logo,
.leed-platinum-logo {
    background-size: contain;
    background-repeat: no-repeat;
}

.leed-logo {
    background-image: url('../assets/images/leed-logo.png');
}

.leed-certified-log {
    background-image: url('../assets/images/leed-certified.png');
}

.leed-silver-logo {
    background-image: url('../assets/images/leed-silver.png');
}

.leed-platinum-logo {
    background-image: url('../assets/images/leed-platinum.png');
}