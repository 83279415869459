mat-card.mat-mdc-card {
    border-radius: 20px;
    border: 1px solid #E7E7E7;
    box-shadow: none
}

mat-expansion-panel.mat-expansion-panel {
    border-radius: 12px;
    box-shadow: none;
    border: 1px solid var(--usgbc-gray-3, #E7E7E7);

    &:not([class*="mat-elevation-z"]) {
        box-shadow: none;
    }

    &:last-of-type {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &:first-of-type {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
}