button {
    &.mdc-button,
    &.mat-mdc-button.mat-mdc-button-base,
    &.mat-mdc-raised-button.mat-mdc-button-base,
    &.mat-mdc-unelevated-button.mat-mdc-button-base,
    &.mat-mdc-outlined-button.mat-mdc-button-base {
        display: inline-flex;
        height: 40px;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex-shrink: 0;
        border-radius: 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.14px;
    }

    &.mat-mdc-outlined-button:not(:disabled) {
        border: 1px solid $usgbc-primary-1;
    }
}