/* stylelint-disable selector-class-pattern */

/* FILLED MAT-FORM-FIELD */
.mat-mdc-form-field.mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
        background: transparent;
    }
}

/* OUTLINED MAT-FORM-FIELD */
.mat-mdc-form-field.mat-form-field-appearance-outline {
    margin: 0 0 24px;
    padding-top: 26px;
    width: 100%;

    .mat-mdc-text-field-wrapper {
        background: white;
        border-radius: 8px;
        padding: 0 18px;
        max-height: 40px;

        .mat-mdc-form-field-flex {
            .mdc-notched-outline__notch {
                position: absolute;
                top: 0;
                left: 0;
                border: none;

                // TODO: Material sets this width directly to the element style.
                // We need to investigate how to override this attribute without using !important.
                width: 100% !important;
                max-width: 100%;

                .mat-mdc-floating-label {
                    position: absolute;
                    top: -20px;
                    left: 0;
                    transform: none;
                    transition: none;
                    width: 100%;
                    color: $usgbc-primary-1;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 14px;
                    letter-spacing: 0.14px;
                    height: 20px;
                    display: flex;
                    flex-direction: column;
                    overflow-wrap: break-word;
                }
            }

            .mat-mdc-form-field-infix {
                padding: 9px 0;
                min-height: auto;

                input {
                    color: $usgbc-primary-1;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.16px;
                }
            }

            .mdc-notched-outline__trailing,
            .mdc-notched-outline__leading {
                border: 1px solid $usgbc-gray-4;
            }

            .mdc-notched-outline__leading {
                border-right: 0;
                border-radius: 8px 0 0 8px;
            }

            .mdc-notched-outline__trailing {
                border-radius: 0 8px 8px 0;
                border-left: 0;
            }
        }

        &.mdc-text-field--focused {
            .mat-mdc-form-field-flex {
                .mdc-notched-outline__trailing,
                .mdc-notched-outline__leading {
                    border-color: $usgbc-primary-1;
                }
            }
        }

        &.mdc-text-field--invalid {
            .mat-mdc-form-field-flex {
                .mdc-notched-outline__trailing,
                .mdc-notched-outline__leading {
                    border-color: $usgbc-error-1;
                }
            }
        }
    }

    &.described {
        padding-top: 46px;

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline__notch .mat-mdc-floating-label {
            top: -40px;
            height: 40px;

            .description {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-top: 4px;
                overflow-wrap: break-word;
            }
        }

    }

    &.disabled {
        .mat-mdc-text-field-wrapper {
            background: $usgbc-gray-1;

            .mat-mdc-form-field-flex {
                .mdc-notched-outline__notch {
                    .mat-mdc-floating-label {
                        color: #949494;
                    }
                }

                .mdc-notched-outline__trailing,
                .mdc-notched-outline__leading {
                    border-color: $usgbc-gray-4;
                }
            }
        }
    }
}

.no_explain .mat-mdc-text-field-wrapper {
    max-height: 500px !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob, .mat-mdc-slider .mdc-slider__track--active_fill, .mat-mdc-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob {
    border-color: #00C08B !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
    width: var(--mdc-slider-handle-width, 15px) !important;
    height: var(--mdc-slider-handle-height, 15px) !important;
    border-width: calc(var(--mdc-slider-handle-height, 15px) / 2) calc(var(--mdc-slider-handle-width, 15px) / 2) !important;
}