* {
    font-family: "Source Sans Pro", sans-serif;
}

h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -1.28px;
    margin: 0px;
}

h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0px;
}

h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.8px;
    margin: 0px;
}

h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
}

h5 { 
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: .16px;
    margin: 0px;
}

a {
    color: var(--gray-usgbc-gray-6, #4a4a4a);
    font-weight: 600;
    text-decoration-line: underline;
}

.subheading-alt {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
}

.paragraph-alt {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.information {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.16px;
}

a {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;

    &.small {
        font-size: 13px;
    }

    &.large {
        font-size: 16px;
    }
}

.caption {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.disclaimer {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.mini-text {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &.small {
        font-size: 11px;
    }
}

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.underline {
    text-decoration-line: underline;
}