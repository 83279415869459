// TODO: apply usgbc prefix and naming convention
// Variables size
$app-mobile-min: 320px;
$app-mobile-max: 480px;
$app-desktop-min: 481px;
$app-card-max: 440px;

// COLORS
$usgbc-primary-1: #353535;
$usgbc-primary-3: #00C08B;
$usgbc-white-2: #FEFEFE;
$usgbc-gray-1: #FAF9F9;
$usgbc-gray-2: #F0F0F0;
$usgbc-gray-3: #E7E7E7;
$usgbc-gray-4: #C6C6C6;
$usgbc-gray-5: #949494;
$usgbc-error-1: #FF3838;
$usgbc-error-3: #FFE7E7;

// Variables toolbar
$mat-toolbar-height: 72px;
$mat-toolbar-background-color: #FFF;
$mat-toolbar-text-color: $usgbc-primary-1;