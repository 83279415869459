mat-table.mat-mdc-table {
    border-radius: 12px;
    border: 1px solid $usgbc-gray-3;
    background: transparent;
    box-shadow: none;

    .mat-mdc-header-cell {
        color: var(--usgbc-gray-5, #949494);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-transform: capitalize;
    }
}